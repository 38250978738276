import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  public dictionary = [] // dictionary to store default names

  public current: any[]; // for use in telemetry component


  fieldsFromKeys(keys: string[]) { // field names using dictionary format

    var dict = [];


    keys.forEach((key) => {
      var entry: any;

      entry = this.dictionary.find(el => el['header'] == key); // check if in dictionary

      if (entry) dict.push(entry)
      else dict.push({ name: key, header: key })
    })

    if (dict[0]['name'] == '__typename') // remove typename 
      dict.splice(0, 1);

    this.current = dict;

    return dict;
  }

  fieldsFromString(fields: string) {

    var dict = [];
    var jsonarr: any[];

    if (fields.endsWith('}}')) // to check if its a proper object
    {
      var fieldobj = JSON.parse(fields);
      Object.keys(fieldobj).forEach((key) => {
        if (key == "timestamp" || key == "log_time" || key == "ro_timestamp")
          dict.push({ name: fieldobj[key], header: key, pipe: 'date' })
        else
          dict.push({ name: fieldobj[key], header: key })
      })
    }
    else {
      jsonarr = JSON.parse('[' + fields + ']'); // old style string

      jsonarr.forEach((name) => { // read each entry in json to get desired format
        Object.keys(name).forEach((key) => {
          if (key == "timestamp" || key == "log_time" || key == "ro_timestamp")
            dict.push({ name: name[key], header: key, pipe: 'date' })
          else
            dict.push({ name: name[key], header: key })
        })
      })
    }
    this.current = dict;

    return dict;
  }

  constructor() {

    this.dictionary = [
      { name: "Device ID", header: "device_id" },
      { name: "Timestamp", header: "timestamp", pipe: 'date' },
      { name: "Total Dispensed", header: "total_water_dispensed" },
      { name: "TDS", header: "tds_outlet" },
      { name: "PH", header: "ph" },
      { name: "Flow Inlet", header: "flow_inlet" },
      { name: "Flow Reject", header: "flow_reject" },
      { name: "Raw Water Pump Current", header: "current_rwp" },
      { name: "High Pressure Pump Current", header: "currenthpp" },
      { name: "Total Treated Water", header: "total_treated_water" },
      { name: "Total Rejected Water", header: "total_reject_water" },
      { name: "TDS Backup", header: "real_tds" },
      { name: "Log Time", header: "log_time", pipe: 'date' },


      { name: "PH backup", header: "real_ph" },
      { name: "Tank Level", header: "tank_level" },
      { name: "Longitude", header: "longitude" },
      { name: "Total coin count 1", header: "total_coin_count_1" },
      { name: "Total coin count 2", header: "total_coin_count_2" },
      { name: "Total sale", header: "total_sale" },
      { name: "Total recharge", header: "total_recharge" },
      { name: "total collection card", header: "total_collection_card" },
      { name: "Total collection coin", header: "total_collection_coin" },
      { name: "Total count volume 1", header: "total_count_volume_1" },
      { name: "Total count volume 2", header: "total_count_volume_2" },
      { name: "Total count volume 3", header: "total_count_volume_3" },
      { name: "Total count volume 4", header: "total_count_volume_4" },
      { name: "Total count_volume 5", header: "total_count_volume_5" },
      { name: "Purifier Runtime", header: "operational_minutes" },
      { name: "Backwash Count", header: "backwash_count" },
      { name: "Temperature", header: "temperature" },
      { name: "Inlet TDS", header: "tds_inlet" },
      { name: "UV state", header: "uv_state" },
      { name: "Trip state", header: "trip_state" },
      { name: "RO data timestamp", header: "ro_timestamp", pipe: 'date' },
      { name: "Date", header: "date" }

    ]
  }



}
