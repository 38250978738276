import { AuthModel } from './auth.model';


export class UserModel extends AuthModel {
  username: string;
  password: string
  email: string;
  company: string;
  phone: number;
  // personal information
  name: string;
  sub: string;
  roles?: any[];


  setUser(user: any) {
    this.username = user.username ;
    this.password = user.password || '';
    this.email = user.email || '';
    this.company = user.company || '';
    this.phone = user.phone || '';
    this.sub = user.sub || '';
    this.name = user.name || '';
  }
}
