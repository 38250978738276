import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify'; // Aws auth module which uses cognito
import { UNDERSCORE_IDENT_RE } from 'highlight.js';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isAdmin = false;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);

    return from(Auth.signIn(email, password)).pipe(  // turn the auth promise into an observable so that we can pipe it to use metronic's auth flow
      map((user) => {

        var role = user.attributes["custom:roles"].split(',');
        if (role.includes('1')) // check if user has 1 role for admin
          this.isAdmin = true;
        else
          this.isAdmin = false; 
      
        var usermodel = new UserModel;
        usermodel.setUser(  // retrieve relevant information from the AWS return to set our user model
          {
            username: user.attributes.email,
            email: user.attributes.email,// split to make arrays
            company: user.attributes["custom:company"],
            roles: role,
            phone: user.attributes.phone_number,
            name: user.attributes.name,
            sub: user.attributes.sub,
          }
        );
        usermodel.setAuth( // set the requirements for the Auth model
          {
            accessToken: user.signInUserSession.refreshToken.token,
            refreshToken: user.signInUserSession.accessToken.jwtToken,
            expiresIn: new Date(user.signInUserSession.accessToken.auth_time * 1000)
          }
        )



        return usermodel;
      }),
      map((user: UserModel) => { // from old get UserByToken
        if (user) {
          this.currentUserSubject.next(user) // passing to maintain metronic's process
        } else {
          this.logout();
        }
        return user;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))

    )
  }

  logout() {
    try {
      Auth.signOut().then(() => { // let Cognito sign out and then let metronic do it's thing
        localStorage.removeItem(this.authLocalStorageToken);
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
      })
    } catch (error) {
      // console.log('error signing out: ', error);
    }
  }

  getUserByToken(): Observable<UserModel> {

    // if not passed, retrieve from cognito and then pass a new user model
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((cuser) => {
        var role = cuser.attributes["custom:roles"].split(',');
        if (role.includes('1')) // check if user has 1 role for admin
          this.isAdmin = true;
        else
          this.isAdmin = false; 

        var usermodel = new UserModel;
        usermodel.setUser(  // retrieve relevant information from the AWS return to set our user model
          {
            username: cuser.attributes.email,
            email: cuser.attributes.email,
            roles: cuser.attributes["custom:roles"].split(','), // split to make arrays
            phone: cuser.attributes.phone_number,
            name: cuser.attributes.name,
            sub: cuser.attributes.sub,
          }
        );
        usermodel.setAuth( // set the requirements for the Auth model
          {
            accessToken: cuser.signInUserSession.refreshToken.token,
            refreshToken: cuser.signInUserSession.accessToken.jwtToken,
            expiresIn: new Date(cuser.signInUserSession.accessToken.auth_time * 1000)
          }
        )

        this.currentUserSubject.next(usermodel);

        return usermodel;
      }), catchError((err) => {
        console.error('err', err);
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        });
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )


  }


  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);

    try {
      var username = user.email;
      var password = user.password;
      var email = user.email;
      var name = user.name;
      var machine_access = "";
      var roles = "2";
      var phone_number = "+91" + user.phone;
      var company = user.company;
      var newUser = {
        username,
        password,
        attributes: {
          email,
          name,
          phone_number,   // optional - E.164 number convention
          "custom:machine_access": machine_access,
          "custom:company": company,
          "custom:roles": roles// other custom attributes 
        }
      };
      return from(Auth.signUp(newUser)).pipe(
        map((res) => {
          this.isLoadingSubject.next(false);
          return res
        }),
        catchError((err) => {
          console.error('err', err);
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    }
    catch (err) {
      console.error('err', err);
      return of(err);
    }
  }

  confirm(email?: any, code?: any): Observable<string> {
    try {
      Auth.confirmSignUp(email, code).then((res) => {
        return of(res);
      })
    } catch (error) {
      // console.log('error confirming sign up', error);
      return of(error)
    }
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return from(Auth.forgotPassword(email))  // convert forgotpassword promise to an observable
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
