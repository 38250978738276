// Services
// export { CustomersService } from './fake/customers.service'; // You have to comment this, when your real back-end is done
// export { MachinesService } from './fake/machines.service'; // You have to comment this, when your real back-end is done
// export { SpecificationsService } from './fake/specifications.service'; // You have to comment this, when your real back-end is done
// export { CommentsService } from './fake/comments.service'; // You have to comment this, when your real back-end is done
export { TelemetryService } from './telemetry.service'; // You have to uncomment this, when your real back-end is done
export { MachinesService } from './machines.service'; // You have to uncomment this, when your real back-end is done
export { FieldService } from './field.service'; // You have to uncomment this, when your real back-end is done

//export { CommentsService } from './comments.service'; // You have to uncomment this, when your real back-end is done
