import { Telemetry } from '../_models/telemetry.model';
import { Inject, Injectable, OnDestroy } from '@angular/core';

// other imports from table
import { from, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

// recreated table service instead of extending it due to REST API limitations

import { Auth } from 'aws-amplify';
import { APIService, CreateTelemetryInput, ModelSortDirection, UpdateTelemetryInput } from '../../../API.service';
import { Machine } from '../_models/machine.model'
import { ModelStringKeyConditionInput, ModelTelemetryFilterInput } from 'API.service';

import * as moment from 'moment';
import { AuthService } from '../../auth';
import { FieldService } from '.';
import { resolve } from 'path';
import { ProviderMeta } from '@angular/compiler';
import { Console } from 'console';

@Injectable({
  providedIn: 'root'
})

export class TelemetryService implements OnDestroy {

  // Private fields
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters


  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }

  constructor(
    private api: APIService,
    private auth: AuthService,
    private fieldService: FieldService,) {
  }

  async filterGaurd(DeviceID: string): Promise<ModelTelemetryFilterInput> {

    var user = await this.auth.currentUser$.toPromise();

    if (typeof user.roles != 'undefined' && user.roles.includes('1')) {
      return null;
    }
    else
      if (typeof this.fieldService.current != 'undefined')
        return new Promise(resolve => {
          var res: ModelTelemetryFilterInput;

          res = {}
        })

  }

  getSomeTelemetry(DeviceID: string, time?: ModelStringKeyConditionInput): Observable<Telemetry[]> { // less data for chart

    var start = moment(time['between'][0]);
    var end = moment(time['between'][1]);
    var current = end;

    var interval = 4; //  3-8 daysdays, 4 hours

    var diff = end.diff(start);

    if (diff >= 2419100000) interval = 24;// more than 28 days, 24 hours
    else if (diff >= 691100000) interval = 10;// more than 8 days, 10 hours
    else if (diff <= 259200000) interval = 2;// less than 3 days, 2 hours


    var results = [];
    var promises = [];


    while (current >= start) {   // get a single data point from an interval

      promises.push(this.getTelemetry(
        DeviceID,
        { between: [moment(current).subtract(interval, 'hours').toISOString(), current.toISOString()] },
        1
      ).toPromise())
      current.subtract(interval, 'hours');
    }

    promises.push(this.getTelemetry(
      DeviceID,
      { between: [moment(current).subtract(interval, 'hours').toISOString(), current.toISOString()] },
      1
    ).toPromise())

    return from(Promise.all(promises)).pipe(
      map(
        (result) => {
          result.forEach(item => { if (typeof item[0] != 'undefined') results.push(item[0]) }); // We have an array of arrays since get returns arrays
          // console.log(result);
          console.log(results);

          return results;
        }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of(undefined);
      })
    )
  }

  // READ (Returning filtered list of entities)
  getTelemetry(DeviceID: string, time?: ModelStringKeyConditionInput, limit?: number, filter?: ModelTelemetryFilterInput, sort?: string): Observable<Telemetry[]> {
    this._errorMessage.next('');

    var gqlfilter: ModelTelemetryFilterInput;
    gqlfilter = null

    var reqlimit = 5000;
    var timestamp = null;
    var sorted = ModelSortDirection.DESC;

    if (time) { // check if a timestamp condition has been passed
      timestamp = time;
    }

    if (filter) { // check if a filter has been passed
      gqlfilter = filter;
    }

    if (typeof limit == "number") { // check if a limit has been passed
      reqlimit = limit;
    }

    if (sort) { // check if a sort has been passed
      sorted = ModelSortDirection[sort];
    }



    // get 100 items in descending order
    return from(this.api.ListTelemetrys(DeviceID, timestamp, gqlfilter, reqlimit, null, sorted))
      .pipe(
        map(
          (result) => {
            return result.items
          }),
        catchError(err => {
          this._errorMessage.next(err);
          console.error('FIND ITEMS', err);
          return of(undefined);
        })
      )
  }

  // READ (Returning filtered list of entities)
  async getDaily(DeviceID: string, time?: ModelStringKeyConditionInput): Promise<any[]> {

    return new Promise((resolve) => {
      this._errorMessage.next('');
      var results = [];

      var timestamp = null;

      if (time) { // check if a timestamp condition has been passed
        timestamp = time;
      }

      if (timestamp['between']) {

        this.getTelemetry(DeviceID, time).toPromise().then((res) => {
          resolve(this.getDailyFromData(res));
        })

        /**
        var promises = [];
        
                while (curdate >= start) {
                  // for between 1-2 days at max
                  var ts = { between: [moment(curdate).subtract(1, 'day').toISOString(), curdate.toISOString()] };
                  console.log(ts);
                  promises.push(this.getTelemetry(DeviceID, ts, 1).toPromise());
        
                  curdate.subtract(1, 'day');
                }
        
        
                Promise.all(promises).then(res => { // wait for result from each day
                  var last;
        
                  res.forEach((el, i) => {
                    if (el[0]) { // if there is an actual result
                      console.log(el[0]['total_water_dispensed'])
                      if (i != 0) {
                        var diff = last['total_water_dispensed'] - el[0]['total_water_dispensed'];
                        var date = moment(end).subtract(i, 'day').format("YYYY-MM-DD");
                        var res_timestamp = moment(el[0]['timestamp']).toISOString();
                        var span = moment(last['timestamp']).diff(moment(el[0]['timestamp'])) / 3600000
        
                        last = el[0];
        
                        results.push({ device_id: DeviceID, date: date, dispensed: diff, timestamp: res_timestamp, hours_counted: span })
                      }
                      else last = el[0] // if this is the first data point
                    } else { // if no data in that day frame
        
                      var diff = 0;
                      var date = moment(end).subtract(i, 'day').format("YYYY-MM-DD");
                      var res_timestamp = moment(end).subtract(i, 'day').toISOString();
                      var span = 24;
        
                      last = { total_water_dispensed: last['total_water_dispensed'], timestamp: moment(end).subtract(i, 'day').toISOString() };
        
                      results.push({ device_id: DeviceID, date: date, dispensed: diff, timestamp: res_timestamp, hours_counted: span })
                    }
                  })
                }).finally(() => resolve(results))
        */
      }

    });

  }

  getDailyFromData(tele: Telemetry[]) {

    var first_item = tele[0];
    var check = moment(tele[0]['timestamp']);
    var last = tele[0];
    var results = [];

    const sameday = (day1: moment.Moment, day2: moment.Moment) => {
      var a = day1.toArray();
      var b = day2.toArray();
      if (a[0] == b[0] && a[1] == b[1] && a[2] == b[2]) return true;
      else return false
    }

    tele.forEach((item, i) => {
      if (sameday(check, moment(item['timestamp']))) {
        last = item;
      } else {
        results.push({
          device_id: item['device_id'],
          date: check.format("YYYY-MM-DD"),
          timestamp: check.toISOString(),
          dispensed: first_item['total_water_dispensed'] - last['total_water_dispensed']
        })

        while (!(sameday(check.subtract(1, 'day'), moment(item['timestamp'])))) { // for days with no data
          results.push({
            device_id: item['device_id'],
            date: check.format("YYYY-MM-DD"),
            timestamp: check.toISOString(),
            dispensed: 0
          })
        }
        first_item = last;
      }
    });
    // run one final time for the day it ends
    results.push({
      device_id: last['device_id'],
      date: check.format("YYYY-MM-DD"),
      timestamp: check.toISOString(),
      dispensed: first_item['total_water_dispensed'] - last['total_water_dispensed']
    })

    return results;
  }

  async getInfoData(last: Telemetry): Promise<{
    lastweek: number,
    lastmonth: number,
    month1: { name: string, dispensed: number },
    month2: { name: string, dispensed: number },
    month3: { name: string, dispensed: number }
  }> {

    var result= {
      lastweek: 0,
      lastmonth: 0,
      month1: { name: '', dispensed: 0 },
      month2: { name: '', dispensed: 0 },
      month3: { name: '', dispensed: 0 }
    }



    const weightedTWD = async (timestamp: moment.Moment) => {

      var res1, res2, ts1, ts2, res1ts, res2ts, res1twd, res2twd, diff1, diff2;
      ts1 = { le: timestamp.toISOString() };
      ts2 = { ge: timestamp.toISOString() };

      res1 = await this.getTelemetry(last.device_id, ts1, 1).toPromise();

      res2 = await this.getTelemetry(last.device_id, ts2, 1, null, 'ASC').toPromise();

      if (res1[0]) {
        res1ts = moment(res1[0]['timestamp']);
        res1twd = res1[0]['total_water_dispensed']
      } else {
        res1ts = timestamp;
        res1twd = 0
      }
      if (res2[0]) {
        res2ts = moment(res2[0]['timestamp']);
        res2twd = res2[0]['total_water_dispensed']
      } else {
        res2ts = timestamp;
        res2twd = 0
      }

      diff1 = res1ts.diff(timestamp);
      diff2 = res2ts.diff(timestamp);

      var result = (
        (res1twd * (diff1 / (diff1 + diff2))) + 
        (res2twd * (diff2 / (diff1 + diff2)))
        )/1000;

      // get weighted twd average from closest 2 data
      
      return result;
    }

    var weighted, weighted2, month: moment.Moment;

    // these need to be handled better
    //7 days
    var weighted_7day = await weightedTWD(moment().subtract(7, 'days'));

    result.lastweek = Math.round(((last.total_water_dispensed/1000) - weighted_7day));
    
    //30 days
    var weighted_30day = await weightedTWD(moment().subtract(30, 'days'));

    result.lastmonth = Math.round(((last.total_water_dispensed/1000) - weighted_30day));

    //last month
    month = moment([moment().year(), moment().month()]) // get current month

    var weightedm1s = await weightedTWD(month);
    var weightedm1e = await weightedTWD(month.subtract(1, 'month'));

    result.month1 = { name: month.format('MMMM'), dispensed: Math.round((weightedm1s - weightedm1e)) };

    //last 2 month
    var weightedm2e = await weightedTWD(month.subtract(1, 'month'));

    result.month2 = { name: month.format('MMMM'), dispensed: Math.round((weightedm1e - weightedm2e)) };

    //last 3 month
    var weightedm3e = await weightedTWD(month.subtract(1, 'month'));


    result.month3 = { name: month.format('MMMM'), dispensed: Math.round((weightedm2e - weightedm3e)) };

    console.log(result);
    return result
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

}
