import { Machine } from '../_models/machine.model';
import { Inject, Injectable, OnDestroy } from '@angular/core';

// imports from table service
import { PaginatorState } from '../../../_metronic/shared/crud-table/models/paginator.model';
import { ITableState, TableResponseModel } from '../../../_metronic/shared/crud-table/models/table.model';
import { BaseModel } from '../../../_metronic/shared/crud-table/models/base.model';
import { SortState } from '../../../_metronic/shared/crud-table/models/sort.model';
import { GroupingState } from '../../../_metronic/shared/crud-table/models/grouping.model';

// other imports from table
import { from, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import { baseFilter } from '../_services/tablefilter'

// recreated table service instead of extending it due to REST API limitations

import { Auth } from 'aws-amplify';
import { APIService, CreateMachineInput, ModelMachineFilterInput, ModelSortDirection, UpdateMachineInput } from '../../../API.service';


const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

@Injectable({
  providedIn: 'root'
})

export class MachinesService implements OnDestroy {

  // Private fields
  public machines: Machine[]; // if you are here because performance is down due to too many machines, may lord help you.
  public clusters: string[];
  private updateList = true;
  private _items$ = new BehaviorSubject<Machine[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters
  get items$() {
    return this._items$.asObservable();
  }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }


  constructor(private api: APIService) {
    this.machines = [];
    this.clusters = [];
  }


  // CREATE
  // server should return the object with ID
  create(item: Machine): Observable<any> {
    this._isLoading$.next(true);
    this.updateList = true;
    this._errorMessage.next('');
    var input: CreateMachineInput;
    input = { any: 1, id: 0 };
    for (const [key, value] of Object.entries(item)) {
      input[key] = value;
    }    

    return from(this.api.ListMachines(1, null, null, 1, null, ModelSortDirection.DESC)).pipe(
      switchMap((res) => {
        var serial = res['items'][0].serial_no + 1;
        input.id = Number(serial + "" + input.id)
        input.serial_no = serial;
        return from(this.api.CreateMachine(input))
      }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // get all Machines
  getAllMachines(): Observable<BaseModel[] | { id: any }> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    var machines = '';
    if (!this.updateList) {
      return of(this.machines)
    }
    else {
      this.machines = [];
      return from(Auth.currentAuthenticatedUser()).pipe(
        switchMap((res) => {
          this.updateList = false;
          machines = res.attributes['custom:machine_access'] || '';
          return from(this.api.ListMachines(1, null, null, 10000, null, null));
        }),
        switchMap((res) => {
          if (machines != '') { // if user can only see some machines
            const allowed = machines.split(',')
            res.items.forEach((result) => {
              if (allowed.includes('' + result.id)) {
                this.machines.push(result);
                if (!(this.clusters.includes(result.cluster))) {
                  this.clusters.push(result.cluster);
                }
              }
            })
          } else { // if user can see all machines
            res.items.forEach((result) => {
              this.machines.push(result);
              if (!(this.clusters.includes(result.cluster))) {
                this.clusters.push(result.cluster);
              }

            })
          }

          // console.log(this.machines);
          return of(this.machines);
        }),
        catchError(err => {
          this._errorMessage.next(err);
          console.error('Get Machines', err);
          return of({ id: undefined });
        }),
        finalize(() => this._isLoading$.next(false))
      );
    }
  }

  find(tableState: ITableState): Observable<TableResponseModel<Machine>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    // init all params for query

    return this.getAllMachines().pipe(
      map(
        () => {
          const responseObj = baseFilter(this.machines, tableState)
          return responseObj
        }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    )
  }


  // READ (Returning filtered list of entities) I have saved this for later in case I need to use indexes again
  oldfind(tableState: ITableState): Observable<TableResponseModel<Machine>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    // init all params for query
    var gqlfilter: ModelMachineFilterInput;
    gqlfilter = null

    // console.log(tableState)

    var sort = ((tableState.sorting.direction == 'asc') ? ModelSortDirection.ASC : ModelSortDirection.DESC);
    var index = tableState.sorting.column;
    var indexName = null;

    // adding index for sort

    if (index == 'serial_no')
      indexName = 'any-serial_no-index';
    else if (index == 'cluster')
      indexName = 'cluster-index'
    else if (index == 'device_id')
      indexName = 'device_id-index'
    else if (index == 'location')
      indexName = 'location-index'
    else if (index == 'monitor_access')
      indexName = 'monitor_access-index'
    else if (index == 'id')
      indexName = null;

    // handling the result manipulation for the table
    // filtration
    // console.log(tableState.filter);
    var filter = tableState.filter;

    if (Object.keys(filter).length !== 0) {
      // console.log(tableState.filter)
      gqlfilter = {}
      const filtrationFields = Object.keys(tableState.filter);
      filtrationFields.forEach((keyName: string) => {
        gqlfilter[keyName] = { eq: tableState.filter[keyName] };
      });
    }

    //search
    if (tableState.searchTerm !== '') {
      gqlfilter = {}
      gqlfilter['and'] = [{
        cluster: { contains: tableState.searchTerm },
        or: [{
          location: { contains: tableState.searchTerm }
        }]
      }]
    }


    // console.log(gqlfilter)
    return from(this.api.ListMachines(1, null, gqlfilter, 400, null, sort)).pipe(
      map(
        (result) => {
          // limiting results to what has been asked for
          const totalCount = result.items.length;
          const startPosition = (tableState.paginator.page - 1) * tableState.paginator.pageSize;
          const endPosition = startPosition + tableState.paginator.pageSize;
          var items = result.items.slice(startPosition, endPosition);
          // console.log(result);
          const responseObj: TableResponseModel<Machine> = {
            items: items,
            total: totalCount
          };

          // console.log(responseObj);

          return responseObj
        }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    )
  }

  getItemById(id: number): Observable<Machine> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    return of(this.machines.find(machine => machine.id == id)).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: Machine): Observable<any> {
    this._isLoading$.next(true);
    this.updateList = true;
    this._errorMessage.next('');
    var input: UpdateMachineInput;
    input = { any: 1, id: 0 }
    const fields = Object.keys(item);
    fields.forEach((keyName: string) => {
      input[keyName] = item[keyName];
    });
    return from(this.api.UpdateMachine(input)).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status 
  updateStatusForItems(ids: number[], status: string): Observable<any> {
    this._errorMessage.next('');
    try {
      ids.forEach(async (id) => {
        await this.api.UpdateMachine({ any: 1, id: id, status: status });
        this.machines.some((machine, index) => {
          if (machine.id === id) {
            this.machines[index].status = status;
            return true
          }
        })
      })

      return of(true)
    } catch (e) {
      return of(e)
    }
  }

  // UPDATE
  updateFields(ids: number[], fields: string): Observable<any> {
    this._isLoading$.next(true);
    this.updateList = true;
    this._errorMessage.next('');
    var input: UpdateMachineInput;
    ids.forEach(async (id) => {
      input = { any: 1, id: id, fields: fields };
      await this.api.UpdateMachine(input);
      if(id == ids[ids.length - 1])
      return of(undefined).pipe(
        catchError(err => {
          this._errorMessage.next(err);
          console.error('UPDATE ITEM', ids, err);
          return of(ids);
        }),
        finalize(() => this._isLoading$.next(false))
      );
    })
    return of(undefined).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', ids, err);
        return of(undefined);
      })
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return from(this.api.DeleteMachine({ any: 1, id: id })).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    ids.forEach((id) => this.api.DeleteMachine({ any: 1, id: id }))
    return of('').pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    // console.log(this._tableState$.value);
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<Machine>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: Machine) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

}
