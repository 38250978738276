/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateTelemetry: OnCreateTelemetrySubscription;
  onUpdateTelemetry: OnUpdateTelemetrySubscription;
  onDeleteTelemetry: OnDeleteTelemetrySubscription;
  onCreateMachine: OnCreateMachineSubscription;
  onUpdateMachine: OnUpdateMachineSubscription;
  onDeleteMachine: OnDeleteMachineSubscription;
};

export type CreateTelemetryInput = {
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;
};

export type ModelTelemetryConditionInput = {
  altitude?: ModelIntInput | null;
  latitude?: ModelIntInput | null;
  log_time?: ModelStringInput | null;
  longitude?: ModelIntInput | null;
  real_ph?: ModelFloatInput | null;
  tank_level?: ModelIntInput | null;
  total_coin_count_1?: ModelIntInput | null;
  total_coin_count_2?: ModelIntInput | null;
  total_sale?: ModelIntInput | null;
  total_recharge?: ModelIntInput | null;
  total_water_dispensed?: ModelFloatInput | null;
  total_collection_card?: ModelIntInput | null;
  total_collection_coin?: ModelIntInput | null;
  total_count_volume_1?: ModelIntInput | null;
  total_count_volume_2?: ModelIntInput | null;
  total_count_volume_3?: ModelIntInput | null;
  total_count_volume_4?: ModelIntInput | null;
  total_count_volume_5?: ModelIntInput | null;
  ph?: ModelFloatInput | null;
  operational_minutes?: ModelIntInput | null;
  backwash_count?: ModelIntInput | null;
  temperature?: ModelFloatInput | null;
  tds_inlet?: ModelIntInput | null;
  tds_outlet?: ModelIntInput | null;
  flow_inlet?: ModelFloatInput | null;
  flow_reject?: ModelFloatInput | null;
  current_rwp?: ModelFloatInput | null;
  currenthpp?: ModelFloatInput | null;
  total_treated_water?: ModelFloatInput | null;
  total_reject_water?: ModelFloatInput | null;
  uv_state?: ModelIntInput | null;
  trip_state?: ModelIntInput | null;
  real_tds?: ModelIntInput | null;
  ro_timestamp?: ModelStringInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelTelemetryConditionInput | null> | null;
  or?: Array<ModelTelemetryConditionInput | null> | null;
  not?: ModelTelemetryConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Telemetry = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type UpdateTelemetryInput = {
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;
};

export type DeleteTelemetryInput = {
  device_id: string;
  timestamp: string;
};

export type CreateMachineInput = {
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;
};

export type ModelMachineConditionInput = {
  cluster?: ModelStringInput | null;
  device_id?: ModelStringInput | null;
  serial_no?: ModelIntInput | null;
  location?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  machine_no?: ModelIntInput | null;
  tds_monitor?: ModelIntInput | null;
  ph_monitor?: ModelIntInput | null;
  monitor_access?: ModelIntInput | null;
  status?: ModelStringInput | null;
  fields?: ModelStringInput | null;
  and?: Array<ModelMachineConditionInput | null> | null;
  or?: Array<ModelMachineConditionInput | null> | null;
  not?: ModelMachineConditionInput | null;
};

export type Machine = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type UpdateMachineInput = {
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;
};

export type DeleteMachineInput = {
  any: number;
  id: number;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelTelemetryFilterInput = {
  altitude?: ModelIntInput | null;
  device_id?: ModelIDInput | null;
  latitude?: ModelIntInput | null;
  log_time?: ModelStringInput | null;
  longitude?: ModelIntInput | null;
  real_ph?: ModelFloatInput | null;
  tank_level?: ModelIntInput | null;
  timestamp?: ModelStringInput | null;
  total_coin_count_1?: ModelIntInput | null;
  total_coin_count_2?: ModelIntInput | null;
  total_sale?: ModelIntInput | null;
  total_recharge?: ModelIntInput | null;
  total_water_dispensed?: ModelFloatInput | null;
  total_collection_card?: ModelIntInput | null;
  total_collection_coin?: ModelIntInput | null;
  total_count_volume_1?: ModelIntInput | null;
  total_count_volume_2?: ModelIntInput | null;
  total_count_volume_3?: ModelIntInput | null;
  total_count_volume_4?: ModelIntInput | null;
  total_count_volume_5?: ModelIntInput | null;
  ph?: ModelFloatInput | null;
  operational_minutes?: ModelIntInput | null;
  backwash_count?: ModelIntInput | null;
  temperature?: ModelFloatInput | null;
  tds_inlet?: ModelIntInput | null;
  tds_outlet?: ModelIntInput | null;
  flow_inlet?: ModelFloatInput | null;
  flow_reject?: ModelFloatInput | null;
  current_rwp?: ModelFloatInput | null;
  currenthpp?: ModelFloatInput | null;
  total_treated_water?: ModelFloatInput | null;
  total_reject_water?: ModelFloatInput | null;
  uv_state?: ModelIntInput | null;
  trip_state?: ModelIntInput | null;
  real_tds?: ModelIntInput | null;
  ro_timestamp?: ModelStringInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelTelemetryFilterInput | null> | null;
  or?: Array<ModelTelemetryFilterInput | null> | null;
  not?: ModelTelemetryFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelTelemetryConnection = {
  __typename: "ModelTelemetryConnection";
  items: Array<Telemetry | null>;
  nextToken?: string | null;
};

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelMachineFilterInput = {
  any?: ModelIntInput | null;
  cluster?: ModelStringInput | null;
  device_id?: ModelStringInput | null;
  serial_no?: ModelIntInput | null;
  location?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  machine_no?: ModelIntInput | null;
  tds_monitor?: ModelIntInput | null;
  ph_monitor?: ModelIntInput | null;
  monitor_access?: ModelIntInput | null;
  status?: ModelStringInput | null;
  fields?: ModelStringInput | null;
  id?: ModelIntInput | null;
  and?: Array<ModelMachineFilterInput | null> | null;
  or?: Array<ModelMachineFilterInput | null> | null;
  not?: ModelMachineFilterInput | null;
};

export type ModelMachineConnection = {
  __typename: "ModelMachineConnection";
  items: Array<Machine | null>;
  nextToken?: string | null;
};

export type CreateTelemetryMutation = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type UpdateTelemetryMutation = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type DeleteTelemetryMutation = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type CreateMachineMutation = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type UpdateMachineMutation = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type DeleteMachineMutation = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type GetTelemetryQuery = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type ListTelemetrysQuery = {
  __typename: "ModelTelemetryConnection";
  items: Array<{
    __typename: "Telemetry";
    altitude?: number | null;
    device_id: string;
    latitude?: number | null;
    log_time?: string | null;
    longitude?: number | null;
    real_ph?: number | null;
    tank_level?: number | null;
    timestamp: string;
    total_coin_count_1?: number | null;
    total_coin_count_2?: number | null;
    total_sale?: number | null;
    total_recharge?: number | null;
    total_water_dispensed?: number | null;
    total_collection_card?: number | null;
    total_collection_coin?: number | null;
    total_count_volume_1?: number | null;
    total_count_volume_2?: number | null;
    total_count_volume_3?: number | null;
    total_count_volume_4?: number | null;
    total_count_volume_5?: number | null;
    ph?: number | null;
    operational_minutes?: number | null;
    backwash_count?: number | null;
    temperature?: number | null;
    tds_inlet?: number | null;
    tds_outlet?: number | null;
    flow_inlet?: number | null;
    flow_reject?: number | null;
    current_rwp?: number | null;
    currenthpp?: number | null;
    total_treated_water?: number | null;
    total_reject_water?: number | null;
    uv_state?: number | null;
    trip_state?: number | null;
    real_tds?: number | null;
    ro_timestamp?: string | null;
    date?: string | null;

  } | null>;
  nextToken?: string | null;
};

export type GetMachineQuery = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type ListMachinesQuery = {
  __typename: "ModelMachineConnection";
  items: Array<{
    __typename: "Machine";
    any: number;
    cluster?: string | null;
    device_id?: string | null;
    serial_no?: number | null;
    location?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    machine_no?: number | null;
    tds_monitor?: number | null;
    ph_monitor?: number | null;
    monitor_access?: number | null;
    status?: string | null;
    fields?: string | null;
    id: number;

  } | null>;
  nextToken?: string | null;
};

export type OnCreateTelemetrySubscription = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type OnUpdateTelemetrySubscription = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type OnDeleteTelemetrySubscription = {
  __typename: "Telemetry";
  altitude?: number | null;
  device_id: string;
  latitude?: number | null;
  log_time?: string | null;
  longitude?: number | null;
  real_ph?: number | null;
  tank_level?: number | null;
  timestamp: string;
  total_coin_count_1?: number | null;
  total_coin_count_2?: number | null;
  total_sale?: number | null;
  total_recharge?: number | null;
  total_water_dispensed?: number | null;
  total_collection_card?: number | null;
  total_collection_coin?: number | null;
  total_count_volume_1?: number | null;
  total_count_volume_2?: number | null;
  total_count_volume_3?: number | null;
  total_count_volume_4?: number | null;
  total_count_volume_5?: number | null;
  ph?: number | null;
  operational_minutes?: number | null;
  backwash_count?: number | null;
  temperature?: number | null;
  tds_inlet?: number | null;
  tds_outlet?: number | null;
  flow_inlet?: number | null;
  flow_reject?: number | null;
  current_rwp?: number | null;
  currenthpp?: number | null;
  total_treated_water?: number | null;
  total_reject_water?: number | null;
  uv_state?: number | null;
  trip_state?: number | null;
  real_tds?: number | null;
  ro_timestamp?: string | null;
  date?: string | null;

};

export type OnCreateMachineSubscription = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type OnUpdateMachineSubscription = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

export type OnDeleteMachineSubscription = {
  __typename: "Machine";
  any: number;
  cluster?: string | null;
  device_id?: string | null;
  serial_no?: number | null;
  location?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  machine_no?: number | null;
  tds_monitor?: number | null;
  ph_monitor?: number | null;
  monitor_access?: number | null;
  status?: string | null;
  fields?: string | null;
  id: number;

};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateTelemetry(
    input: CreateTelemetryInput,
    condition?: ModelTelemetryConditionInput
  ): Promise<CreateTelemetryMutation> {
    const statement = `mutation CreateTelemetry($input: CreateTelemetryInput!, $condition: ModelTelemetryConditionInput) {
        createTelemetry(input: $input, condition: $condition) {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTelemetryMutation>response.data.createTelemetry;
  }
  async UpdateTelemetry(
    input: UpdateTelemetryInput,
    condition?: ModelTelemetryConditionInput
  ): Promise<UpdateTelemetryMutation> {
    const statement = `mutation UpdateTelemetry($input: UpdateTelemetryInput!, $condition: ModelTelemetryConditionInput) {
        updateTelemetry(input: $input, condition: $condition) {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTelemetryMutation>response.data.updateTelemetry;
  }
  async DeleteTelemetry(
    input: DeleteTelemetryInput,
    condition?: ModelTelemetryConditionInput
  ): Promise<DeleteTelemetryMutation> {
    const statement = `mutation DeleteTelemetry($input: DeleteTelemetryInput!, $condition: ModelTelemetryConditionInput) {
        deleteTelemetry(input: $input, condition: $condition) {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTelemetryMutation>response.data.deleteTelemetry;
  }
  async CreateMachine(
    input: CreateMachineInput,
    condition?: ModelMachineConditionInput
  ): Promise<CreateMachineMutation> {
    const statement = `mutation CreateMachine($input: CreateMachineInput!, $condition: ModelMachineConditionInput) {
        createMachine(input: $input, condition: $condition) {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMachineMutation>response.data.createMachine;
  }
  async UpdateMachine(
    input: UpdateMachineInput,
    condition?: ModelMachineConditionInput
  ): Promise<UpdateMachineMutation> {
    const statement = `mutation UpdateMachine($input: UpdateMachineInput!, $condition: ModelMachineConditionInput) {
        updateMachine(input: $input, condition: $condition) {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMachineMutation>response.data.updateMachine;
  }
  async DeleteMachine(
    input: DeleteMachineInput,
    condition?: ModelMachineConditionInput
  ): Promise<DeleteMachineMutation> {
    const statement = `mutation DeleteMachine($input: DeleteMachineInput!, $condition: ModelMachineConditionInput) {
        deleteMachine(input: $input, condition: $condition) {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMachineMutation>response.data.deleteMachine;
  }
  async GetTelemetry(
    device_id: string,
    timestamp: string
  ): Promise<GetTelemetryQuery> {
    const statement = `query GetTelemetry($device_id: ID!, $timestamp: AWSDateTime!) {
        getTelemetry(device_id: $device_id, timestamp: $timestamp) {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      device_id,
      timestamp
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTelemetryQuery>response.data.getTelemetry;
  }
  async ListTelemetrys(
    device_id?: string,
    timestamp?: ModelStringKeyConditionInput,
    filter?: ModelTelemetryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTelemetrysQuery> {
    const statement = `query ListTelemetrys($device_id: ID, $timestamp: ModelStringKeyConditionInput, $filter: ModelTelemetryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTelemetrys(device_id: $device_id, timestamp: $timestamp, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            altitude
            device_id
            latitude
            log_time
            longitude
            real_ph
            tank_level
            timestamp
            total_coin_count_1
            total_coin_count_2
            total_sale
            total_recharge
            total_water_dispensed
            total_collection_card
            total_collection_coin
            total_count_volume_1
            total_count_volume_2
            total_count_volume_3
            total_count_volume_4
            total_count_volume_5
            ph
            operational_minutes
            backwash_count
            temperature
            tds_inlet
            tds_outlet
            flow_inlet
            flow_reject
            current_rwp
            currenthpp
            total_treated_water
            total_reject_water
            uv_state
            trip_state
            real_tds
            ro_timestamp
            date
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (device_id) {
      gqlAPIServiceArguments.device_id = device_id;
    }
    if (timestamp) {
      gqlAPIServiceArguments.timestamp = timestamp;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTelemetrysQuery>response.data.listTelemetrys;
  }
  async GetMachine(any: number, id: number): Promise<GetMachineQuery> {
    const statement = `query GetMachine($any: Int!, $id: Int!) {
        getMachine(any: $any, id: $id) {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      any,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMachineQuery>response.data.getMachine;
  }
  async ListMachines(
    any?: number,
    id?: ModelIntKeyConditionInput,
    filter?: ModelMachineFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMachinesQuery> {
    const statement = `query ListMachines($any: Int, $id: ModelIntKeyConditionInput, $filter: ModelMachineFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMachines(any: $any, id: $id, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            any
            cluster
            device_id
            serial_no
            location
            latitude
            longitude
            machine_no
            tds_monitor
            ph_monitor
            monitor_access
            status
            fields
            id
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (any) {
      gqlAPIServiceArguments.any = any;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMachinesQuery>response.data.listMachines;
  }
  OnCreateTelemetryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTelemetry">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTelemetry {
        onCreateTelemetry {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTelemetry">>
  >;

  OnUpdateTelemetryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTelemetry">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTelemetry {
        onUpdateTelemetry {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTelemetry">>
  >;

  OnDeleteTelemetryListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTelemetry">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTelemetry {
        onDeleteTelemetry {
          __typename
          altitude
          device_id
          latitude
          log_time
          longitude
          real_ph
          tank_level
          timestamp
          total_coin_count_1
          total_coin_count_2
          total_sale
          total_recharge
          total_water_dispensed
          total_collection_card
          total_collection_coin
          total_count_volume_1
          total_count_volume_2
          total_count_volume_3
          total_count_volume_4
          total_count_volume_5
          ph
          operational_minutes
          backwash_count
          temperature
          tds_inlet
          tds_outlet
          flow_inlet
          flow_reject
          current_rwp
          currenthpp
          total_treated_water
          total_reject_water
          uv_state
          trip_state
          real_tds
          ro_timestamp
          date
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTelemetry">>
  >;

  OnCreateMachineListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMachine">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMachine {
        onCreateMachine {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateMachine">>
  >;

  OnUpdateMachineListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMachine">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMachine {
        onUpdateMachine {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateMachine">>
  >;

  OnDeleteMachineListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMachine">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMachine {
        onDeleteMachine {
          __typename
          any
          cluster
          device_id
          serial_no
          location
          latitude
          longitude
          machine_no
          tds_monitor
          ph_monitor
          monitor_access
          status
          fields
          id
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteMachine">>
  >;
}
